import React, { useState, useEffect } from 'react';
import { Trophy, Users } from 'lucide-react';
import { golfService } from '../../../api/services';
import LoadingSpinner from '../../common/LoadingSpinner';
import LeaderboardCard from '../shared/LeaderboardCard';
import MobileEntry from '../shared/MobileEntry';

const LeagueLeaderboard = ({ isDeadlinePassed }) => {
  const [userLeagues, setUserLeagues] = useState([]);
  const [leagueLeaderboards, setLeagueLeaderboards] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  const loadLeagueData = async () => {
    if (!isDeadlinePassed) return;
    
    try {
      setLoading(true);
      setError(null);
      
      const leaguesResponse = await golfService.getAvailableLeagues();
      
      if (!leaguesResponse?.leagues) {
        throw new Error('Invalid leagues response');
      }
 
      const myLeagues = leaguesResponse.leagues.filter(league => 
        league.members.some(member => member.user === leaguesResponse.currentUserId)
      );
      
      setUserLeagues(myLeagues);
 
      const leaderboards = {};
      for (const league of myLeagues) {
        try {
          const leaderboardData = await golfService.getLeagueLeaderboard(league._id);
          leaderboards[league._id] = Array.isArray(leaderboardData) ? 
            leaderboardData : leaderboardData?.data || [];
        } catch (err) {
          console.error(`Error loading leaderboard for league ${league.name}:`, err);
        }
      }
      
      setLeagueLeaderboards(leaderboards);
    } catch (err) {
      console.error('Error loading league data:', err);
      setError('Failed to load league data');
    } finally {
      setLoading(false);
    }
  };
 
  useEffect(() => {
    loadLeagueData();
  }, [isDeadlinePassed]);
 
  const handleRefresh = async () => {
    await loadLeagueData();
  };
 
  if (!isDeadlinePassed) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <p className="text-gray-600">
          League data will be available after the tournament deadline has passed.
        </p>
      </div>
    );
  }
 
  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <LoadingSpinner />
      </div>
    );
  }
 
  if (error) {
    return (
      <LeaderboardCard title="My Leagues" icon={Users}>
        <div className="p-4 text-red-600">{error}</div>
      </LeaderboardCard>
    );
  }
 
  if (userLeagues.length === 0) {
    return (
      <LeaderboardCard title="My Leagues" icon={Users}>
        <div className="p-4">
          <p className="text-gray-600">
            You haven't joined any leagues yet. Visit the My Selections tab to join a league.
          </p>
        </div>
      </LeaderboardCard>
    );
  }
 
  return (
    <div className="space-y-6">
  {userLeagues.map(league => {
    const leaderboardData = leagueLeaderboards[league._id] || [];

    return (
      <LeaderboardCard 
        key={league._id}
        title={league.name}
        icon={Trophy}
        onRefresh={handleRefresh}
        className="bg-gradient-to-br from-white to-blue-50"
      >
        {/* Desktop View */}
        <div className="hidden md:block">
          <table className="w-full">
            <thead>
              <tr className="bg-blue-500/5">
                <th className="px-4 py-3 text-left w-12 text-sm font-semibold text-blue-700">POS</th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-blue-700">PLAYER</th>
                <th className="px-4 py-3 text-left text-sm font-semibold text-blue-700">SELECTIONS</th>
                <th className="px-4 py-3 text-right w-24 text-sm font-semibold text-blue-700">SCORE</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
                {leaderboardData.map((entry, index) => (
                  <tr key={index}>
                    <td className="px-4 py-3">
                      <span className="text-blue-600 font-semibold text-lg">{index + 1}</span>
                    </td>
                    <td className="px-4 py-3">
                      <span className="font-medium text-gray-900">{entry.displayName}</span>
                    </td>
                    <td className="px-4 py-3">
                      <div className="space-y-1.5">
                        {entry.selections.map((selection, i) => (
                          <div key={i} className="flex justify-between text-sm max-w-md px-2 py-1">
                          <span className="text-gray-600">{selection.playerName}</span>
                          <span className={`font-medium ${
                            selection.score === "CUT" ? "text-red-500" :
                            selection.score.startsWith("-") ? "text-green-600" : "text-red-600"
                          }`}>
                            {selection.score}
                          </span>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td className="px-4 py-3 text-right">
                    <span className={`text-xl font-bold ${
                      entry.totalScore < 0 ? "text-green-600" :
                      entry.totalScore > 0 ? "text-red-600" : "text-gray-600"
                    }`}>
                      {entry.totalScore}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile View */}
        <div className="md:hidden divide-y divide-gray-100">
          {leaderboardData.map((entry, index) => (
            <div key={index} className="p-4 hover:bg-blue-50 transition-colors">
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center gap-3">
                  <span className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 text-blue-600 font-semibold">
                    {index + 1}
                  </span>
                  <span className="font-medium text-gray-900">{entry.displayName}</span>
                </div>
                <span className={`text-lg font-bold ${
                  entry.totalScore < 0 ? "text-green-600" :
                  entry.totalScore > 0 ? "text-red-600" : "text-gray-600"
                }`}>
                  {entry.totalScore}
                </span>
              </div>
              <div className="space-y-2 bg-gray-50 rounded-lg p-3">
                {entry.selections.map((selection, i) => (
                  <div key={i} className="flex justify-between items-center">
                    <span className="text-sm text-gray-600">{selection.playerName}</span>
                    <span className={`font-medium ${
                      selection.score === "CUT" ? "text-red-500" :
                      selection.score.startsWith("-") ? "text-green-600" : "text-red-600"
                    }`}>
                      {selection.score}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </LeaderboardCard>
    );
  })}
</div>
  );
 };
 
 export default LeagueLeaderboard;