import React from 'react';
import Home from '../pages/Home';
import Football5sPage from '../pages/football/Football5sPage';
import NFLLMSPage from '../pages/nfl-lms/NFLLMSPage';
import NFLSCPage from '../pages/nfl-sc/NFLSCPage';
import GolfTournamentPage from '../pages/golf/GolfTournamentPage'; 
import Login from '../pages/Login';
import Register from '../pages/Register';
import Admin from '../pages/Admin';
import Profile from '../pages/Profile';
import ForgotPassword from '../components/ForgotPassword';
import ResetPassword from '../components/ResetPassword';
import ProtectedRoute from '../components/common/ProtectedRoute';
import AdminRoute from '../components/common/AdminRoute';
import StaticPage from '../pages/static/StaticPage';

export const routes = [
  { path: "/", element: <Home />, alwaysShow: true },
  { path: "/login", element: <Login />, alwaysShow: true },
  { path: "/register", element: <Register />, alwaysShow: true },
  { path: "/admin", element: <AdminRoute><Admin /></AdminRoute>, alwaysShow: true },
  { path: "/profile", element: <ProtectedRoute><Profile /></ProtectedRoute>, alwaysShow: true },
  { path: "/forgot-password", element: <ForgotPassword />, alwaysShow: true },
  { path: "/reset-password/:token", element: <ResetPassword />, alwaysShow: true },
  { path: "*", element: <div>404: Page not found</div>, alwaysShow: true },
  { 
    path: "/football", 
    element: <Football5sPage />, 
    feature: 'SHOW_FOOTBALL' 
  },
  { 
    path: "/nfl-lms", 
    element: <NFLLMSPage />, 
    feature: 'SHOW_AMERICAN_FOOTBALL' 
  },
  { 
    path: "/nfl-sc", 
    element: <ProtectedRoute><NFLSCPage /></ProtectedRoute>, 
    feature: 'SHOW_NFLSC' 
  },
  { 
    path: "/golf", 
    element: <ProtectedRoute><GolfTournamentPage /></ProtectedRoute>, 
    feature: 'SHOW_GOLF' 
  },
  { path: "/:slug", element: <StaticPage />, alwaysShow: true }
];

export const getGameRoutes = () => {
  return routes.filter(route => route.feature);
};