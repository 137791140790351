import React, { useState, useEffect } from 'react';
import { fetchCurrentGameFixtures } from '../api/football';
import { fetchPage, fetchAdvertisements } from '../api/services/strapiService';
import { useAuth } from '../AuthContext';
import { useFeatureFlags } from '../context/FeatureFlagsContext';
import LoadingSpinner from '../components/common/LoadingSpinner';
import ErrorMessage from '../components/common/ErrorMessage';
import HeroBanner from '../components/home/HeroBanner';
import GameSection from '../components/home/GameSection';
import AdSection from '../components/home/AdSection';
import AdStrip from '../components/common/AdStrip';

const Home = () => {
 const [currentGame, setCurrentGame] = useState(null);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);
 const [pageContent, setPageContent] = useState(null);
 const [advertisements, setAdvertisements] = useState({
   sidebar: [],
   'hero-bottom': [],
   'banner-strip': [],
   'card-strip': []
 });
 
 const { user } = useAuth();
 const { isMainSite } = useFeatureFlags();

 useEffect(() => {
   const loadData = async () => {
     try {
       const promises = [fetchCurrentGameFixtures()];
       
       if (isMainSite) {
         console.log('Loading main site content');
         promises.push(fetchPage('home'));
         
         const [heroAds, sidebarAds, bannerAds, cardAds] = await Promise.all([
           fetchAdvertisements('hero-bottom'),
           fetchAdvertisements('sidebar'),
           fetchAdvertisements('banner-strip'),
           fetchAdvertisements('card-strip')
         ]);

         setAdvertisements({
           'hero-bottom': heroAds || [],
           sidebar: sidebarAds || [],
           'banner-strip': bannerAds || [],
           'card-strip': cardAds || []
         });
       }

       const [gameData, pageData] = await Promise.all(promises);
       setCurrentGame(gameData.currentGame);
       
       if (isMainSite) {
         setPageContent(pageData);
       }
     } catch (err) {
       console.error('Error in loadData:', err);
       if (err.message !== 'No active game found') {
         setError(`Failed to load data: ${err.message}`);
       }
     } finally {
       setLoading(false);
     }
   };

   loadData();
 }, [isMainSite]);

 if (loading) return <LoadingSpinner />;
 if (error) return <ErrorMessage message={error} />;

 return (
   <div className="min-h-screen bg-gray-50">
     {isMainSite ? (
       <>
         <div className="px-4 space-y-4">
           <div className="pt-4">
             <HeroBanner banners={pageContent?.hero?.banners} />
           </div>
           
           {advertisements['banner-strip'].length > 0 && (
             <div>
               <AdStrip 
                 ads={advertisements['banner-strip']} 
                 type="banner"
                 className="rounded-lg overflow-hidden shadow-lg"
               />
             </div>
           )}

           {advertisements['card-strip'].length > 0 && (
             <div className="mt-4">
               <AdStrip 
                 ads={advertisements['card-strip']} 
                 type="card"
                 className="rounded-lg overflow-hidden shadow-lg"
               />
             </div>
           )}
 
           <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
             <div className="lg:col-span-2">
               <GameSection 
                 currentGame={currentGame}
                 isMainSite={isMainSite}
               />
             </div>
             
             <div className="lg:col-span-1">
               <AdSection 
                 advertisements={advertisements.sidebar} 
                 position="sidebar"
               />
             </div>
           </div>

           <div className="mt-8">
             <AdSection 
               advertisements={advertisements['hero-bottom']} 
               position="hero-bottom"
             />
           </div>
         </div>
       </>
     ) : (
       <div className="football-only-site">
         <div className="bg-emerald-600 px-4 py-6">
           <h1 className="text-3xl font-bold text-white">5 to Score</h1>
           <p className="text-white/90 mt-2">
             Pick 5 players to score this week
           </p>
         </div>
         <div className="px-4 py-8">
           <GameSection 
             currentGame={currentGame}
             isMainSite={isMainSite}
           />
         </div>
       </div>
     )}
   </div>
 );
};

export default Home;