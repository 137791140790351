import React from 'react';
import { RefreshCw } from 'lucide-react';
import Card from '../shared/Card';
import usePlayerManagement from '../../../hooks/golf/usePlayerManagement';
import LoadingSpinner from '../../common/LoadingSpinner';

const PlayerManagement = () => {
  const {
    players,
    loading,
    error,
    syncMessage,
    syncPlayers,
    updatePlayerOdds,
    refreshPlayers
  } = usePlayerManagement();

  if (loading) return <LoadingSpinner />;

  return (
    <div className="space-y-6">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 p-4 rounded-lg">
          {error}
        </div>
      )}

      {syncMessage && (
        <div className="bg-green-50 border border-green-200 text-green-700 p-4 rounded-lg">
          {syncMessage}
        </div>
      )}

      <Card>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Player Management</h2>
          <div className="flex gap-2">
            <button
              onClick={syncPlayers}
              className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Sync Players from Sheets
            </button>
            <button
              onClick={refreshPlayers}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
              title="Refresh player list"
            >
              <RefreshCw className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50 border-b">
                <th className="text-left py-3 px-4 font-medium text-gray-600">Name</th>
                <th className="text-center py-3 px-4 font-medium text-gray-600">Odds</th>
                <th className="text-right py-3 px-4 font-medium text-gray-600">Current Position</th>
                <th className="text-right py-3 px-4 font-medium text-gray-600">Total Score</th>
                <th className="text-right py-3 px-4 font-medium text-gray-600">Last Updated</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {players.map((player, index) => (
                <tr 
                  key={player._id}
                  className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                >
                  <td className="py-3 px-4">
                    <span className="font-medium">{player.name}</span>
                  </td>
                  <td className="py-3 px-4 text-center">
                    <div className="flex items-center justify-center gap-2">
                      <input
                        type="number"
                        value={player.odds}
                        onChange={(e) => updatePlayerOdds(player._id, parseInt(e.target.value))}
                        className="w-20 p-1 border rounded text-center"
                      />
                      <span className="text-gray-500">/1</span>
                    </div>
                  </td>
                  <td className="py-3 px-4 text-right">
                    {player.tournamentPosition || '-'}
                  </td>
                  <td className="py-3 px-4 text-right">
                    {player.tournamentScore || '-'}
                  </td>
                  <td className="py-3 px-4 text-right text-gray-500">
                    {player.lastUpdated 
                      ? new Date(player.lastUpdated).toLocaleString() 
                      : '-'
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {players.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No players available. Use the sync button to load players from sheets.
          </div>
        )}
      </Card>
    </div>
  );
};

export default PlayerManagement;