import { useState, useEffect } from 'react';
import { golfAdminService } from '../../api/services';
import useGolfAdminBase from './useGolfAdminBase';

export const useLeagueManagement = () => {
  const { loading, error, syncMessage, setLoading, setError, setSyncMessage, handleError } = useGolfAdminBase();
  const [leagues, setLeagues] = useState([]);
  const [newLeagueName, setNewLeagueName] = useState('');

  useEffect(() => {
    loadLeagues();
  }, []);

  const loadLeagues = async () => {
    try {
        setLoading(true);
        setError(null);
        
        const leaguesData = await golfAdminService.getLeaguesForGame();
        console.log('Leagues data received:', leaguesData);
        
        // Handle the case where leaguesData might be null or undefined
        setLeagues(Array.isArray(leaguesData) ? leaguesData : []);
    } catch (err) {
        handleError(err, 'Failed to fetch leagues');
        setLeagues([]); // Set empty array on error
    } finally {
        setLoading(false);
    }
};

  const createLeague = async (e) => {
    e.preventDefault();
    try {
      setSyncMessage('Creating league...');
      await golfAdminService.createLeague(newLeagueName);
      setSyncMessage('League created successfully!');
      setNewLeagueName('');
      await loadLeagues();
    } catch (err) {
      handleError(err, 'Failed to create league');
    }
  };

  return {
    leagues,
    loading,
    error,
    syncMessage,
    newLeagueName,
    setNewLeagueName,
    createLeague,
    refreshLeagues: loadLeagues  // Renamed to match pattern in other hooks
  };
};

export default useLeagueManagement;