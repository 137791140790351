import React from 'react';
import { NavLink } from 'react-router-dom';
import { cn } from '../../../utils/cn';
import { layout } from '../../../utils/layout';

export const NavigationLink = ({ 
  to, 
  children, 
  className,
  isMobile = false 
}) => (
  <NavLink
    to={to}
    className={({ isActive }) => cn(
      'transition-colors duration-200',
      isMobile ? (
        cn(
          'block w-full text-left px-4 py-2 text-sm',
          layout.colors.primary.text,
          'hover:bg-purple-700 hover:text-white'
        )
      ) : (
        cn(
          'px-3 py-2 rounded-md text-sm font-medium',
          isActive ? 'bg-purple-700 text-white' : 'text-purple-200 hover:bg-purple-700 hover:text-white'
        )
      ),
      className
    )}
  >
    {children}
  </NavLink>
);

export const NavigationButton = ({ 
  onClick, 
  children, 
  className,
  isMobile = false 
}) => (
  <button
    onClick={onClick}
    className={cn(
      'transition-colors duration-200',
      isMobile ? (
        cn(
          'block w-full text-left px-4 py-2 text-sm',
          layout.colors.primary.text,
          'hover:bg-purple-700 hover:text-white'
        )
      ) : (
        cn(
          'px-3 py-2 rounded-md text-sm font-medium',
          'text-purple-200 hover:bg-purple-700 hover:text-white'
        )
      ),
      className
    )}
  >
    {children}
  </button>
);