import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Navigation from '../Navigation';
import Footer from '../Footer';
import LoadingSpinner from '../../common/LoadingSpinner';
import ErrorMessage from '../../common/ErrorMessage';
import { layout } from '../../../utils/layout';
import { cn } from '../../../utils/cn';

const ErrorFallback = ({ error }) => (
  <div className={cn(layout.container, layout.spacing.section)}>
    <ErrorMessage message={error.message} />
  </div>
);

const Layout = ({ children }) => (
  <div className={cn(
    'min-h-screen flex flex-col',
    layout.colors.background
  )}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Navigation />
      <main className={cn(
        'flex-grow',
        layout.container,
        layout.spacing.section
      )}>
        <Suspense fallback={<LoadingSpinner />}>
          {children}
        </Suspense>
      </main>
      <Footer />
    </ErrorBoundary>
  </div>
);

export default Layout;