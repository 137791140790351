import { useState } from 'react';
import { golfAdminService } from '../../api/services';
import useGolfAdminBase from './useGolfAdminBase';

export const useDataSync = () => {
  const { loading, error, syncMessage, setError, setSyncMessage, handleError } = useGolfAdminBase();
  const [lastSyncTime, setLastSyncTime] = useState(null);

  const syncTournamentData = async () => {
    try {
      setSyncMessage('Syncing tournament data...');
      const response = await golfAdminService.syncTournament();
      setLastSyncTime(new Date());
      setSyncMessage('Tournament data synced successfully!');
      return response;
    } catch (err) {
      handleError(err, 'Failed to sync tournament data');
    }
  };

  return {
    lastSyncTime,
    loading,
    error,
    syncMessage,
    syncTournamentData
  };
};

export default useDataSync;