import React, { useState, useEffect } from 'react';
import { Calendar, Trophy, Edit } from 'lucide-react';
import { useAuth } from '../../AuthContext';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { formatHandicap } from '../../utils/formatHandicap';
import { 
    fetchCurrentNflGameFixtures,
    submitNflSelection,
    fetchUserNflSelections,
    fetchAllNflSelections,
    getNflLeaderboard
} from '../../api/nfl';

const NFLSCPage = () => {
    const [currentGame, setCurrentGame] = useState(null);
    const [matches, setMatches] = useState([]);
    const [userSelections, setUserSelections] = useState(null);
    const [allSelections, setAllSelections] = useState([]);
    const [selections, setSelections] = useState([]);
    const [leaderboardData, setLeaderboardData] = useState({
        cumulativeLeaderboard: [],
        gameweekBreakdown: [],
        gameweeks: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [activeTab, setActiveTab] = useState('selections');
    const { user } = useAuth();
    const [isEditing, setIsEditing] = useState(false);

    const isDeadlinePassed = currentGame ? new Date() > new Date(currentGame.deadline) : false;

    useEffect(() => {
        loadData();
    }, [user]);

    const loadData = async () => {
      try {
          setIsLoading(true);
          const [gameData, leaderboardResponse] = await Promise.all([
              fetchCurrentNflGameFixtures('nfl-sc'), // This returns data with gameVariant: 'nfl-sc'
              getNflLeaderboard('nfl-sc') // Update this to use 'nfl-sc' instead of 'sc'
          ]);
  
          setCurrentGame(gameData.currentGame);
          setMatches(gameData.matches);
          setLeaderboardData(leaderboardResponse);
  
          if (gameData.currentGame) {
              const selectionsData = await fetchUserNflSelections();
              const currentSelections = selectionsData.find(s => s.gameweek === gameData.currentGame.name);
              
              if (currentSelections) {
                  setUserSelections(currentSelections);
                  setSelections(currentSelections.selections.map(s => ({
                      matchId: s.match._id,
                      selectedTeam: s.selectedTeam,
                      handicap: s.handicap
                  })));
              }
  
              const deadline = new Date(gameData.currentGame.deadline);
              const now = new Date();
              if (now > deadline) {
                  const allSelectionsData = await fetchAllNflSelections(gameData.currentGame.name);
                  setAllSelections(allSelectionsData);
              }
          }
      } catch (err) {
          console.error('Error loading data:', err);
          setError('Failed to load data. Please try again later.');
      } finally {
          setIsLoading(false);
      }
  };

    const handleSelectionChange = (matchId, team, handicap) => {
        setSelections(prev => {
            const existingSelection = prev.find(s => s.matchId === matchId);
            
            if (existingSelection && existingSelection.selectedTeam === team) {
                return prev.filter(s => s.matchId !== matchId);
            } else {
                const withoutCurrentMatch = prev.filter(s => s.matchId !== matchId);
                
                if (prev.length >= 5 && !existingSelection) {
                    setError("You can only select 5 teams. Please deselect a team before making a new selection.");
                    return prev;
                }
                
                return [...withoutCurrentMatch, { matchId, selectedTeam: team, handicap }];
            }
        });
    };

    const handleEdit = () => {
        setSuccessMessage(null);
        
        if (userSelections && userSelections.selections) {
            const formattedSelections = userSelections.selections.map(selection => ({
                matchId: selection.match,
                selectedTeam: selection.selectedTeam,
                handicap: selection.handicap
            }));
            setSelections(formattedSelections);
        }
        
        setIsEditing(true);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccessMessage(null);

        if (selections.length !== 5) {
            setError('Please make exactly 5 selections before submitting.');
            return;
        }

        try {
            console.log('Submitting selections:', {
                gameweek: currentGame.name,
                selectionId: userSelections?._id,
                selections
            });
            
            const result = await submitNflSelection(
                currentGame.name, 
                selections,
                userSelections?._id
            );
            
            console.log('Submission result:', result);
            setUserSelections(result);
            setSuccessMessage('Selections updated successfully!');
            setIsEditing(false);
            await loadData();
        } catch (err) {
            console.error('Error submitting selections:', err);
            setError(err.message || 'Failed to submit selections. Please try again.');
        }
    };

    const renderHeader = () => (
        <div className="bg-gradient-to-br from-indigo-600 to-indigo-800 text-white">
            <div className="container mx-auto px-4 py-4 sm:py-8">
                <div className="max-w-4xl mx-auto">
                    <h1 className="text-2xl sm:text-4xl font-bold mb-2">NFL Super Contest</h1>
                    {currentGame && (
                        <div className="space-y-1 sm:space-y-2">
                            <h2 className="text-xl sm:text-2xl font-medium opacity-90">{currentGame.name}</h2>
                            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-6 space-y-2 sm:space-y-0">
                                <div className="flex items-center">
                                    <Calendar className="w-4 h-4 sm:w-5 sm:h-5 mr-2 opacity-75" />
                                    <span className="text-xs sm:text-sm">
                                        Deadline: {new Date(currentGame.deadline).toLocaleString()}
                                    </span>
                                </div>
                                <div className="flex items-center">
                                    <Trophy className="w-4 h-4 sm:w-5 sm:h-5 mr-2 opacity-75" />
                                    <span className="text-xs sm:text-sm">Prize Pool: $1000</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );

    const renderTabs = () => (
        <div className="border-b border-gray-200 mt-0">
            <nav className="flex space-x-4 px-4" aria-label="Tabs">
                <button
                    onClick={() => setActiveTab('selections')}
                    className={`px-3 py-2 text-sm font-medium border-b-2 ${
                        activeTab === 'selections'
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                >
                    Selections
                </button>
                <button
                    onClick={() => setActiveTab('leaderboard')}
                    className={`px-3 py-2 text-sm font-medium border-b-2 ${
                        activeTab === 'leaderboard'
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                >
                    Leaderboard
                </button>
            </nav>
        </div>
    );

    const renderSelections = () => {
        if (!user) {
            return (
                <div className="bg-white rounded-lg shadow-sm p-6">
                    <p className="text-lg">
                        Please <a href="/login" className="text-indigo-600 hover:underline">log in</a> to make or view selections.
                    </p>
                </div>
            );
        }

        return (
            <div className="space-y-6">
                {userSelections && (
                    <div className="bg-white rounded-lg shadow-sm p-6">
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-2xl font-bold">My Selections</h2>
                            {!isDeadlinePassed && (
                                <button
                                    onClick={handleEdit}
                                    className="flex items-center px-4 py-2 text-sm font-medium text-indigo-600 border border-indigo-600 rounded-lg hover:bg-indigo-50 transition-colors"
                                >
                                    <Edit className="w-4 h-4 mr-2" />
                                    Edit Selections
                                </button>
                            )}
                        </div>
                        <div className="space-y-3">
                            {userSelections.selections.map((selection, index) => (
                                <div key={index} className="flex items-center justify-between p-3 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors">
                                    <div className="flex items-center">
                                        <span className="font-bold text-lg text-gray-400 w-8">{index + 1}.</span>
                                        <span className="font-medium">{selection.selectedTeam} ({formatHandicap(selection.handicap)})</span>
                                    </div>
                                    {isDeadlinePassed && (
                                        <span className={`px-2 py-1 rounded-full text-sm font-medium ${
                                            selection.result === 'Win' ? 'bg-green-100 text-green-800' :
                                            selection.result === 'Loss' ? 'bg-red-100 text-red-800' :
                                            selection.result === 'Push' ? 'bg-yellow-100 text-yellow-800' :
                                            'bg-gray-100 text-gray-600'
                                        }`}>
                                            {selection.result || 'Pending'}
                                        </span>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                
{!isDeadlinePassed ? (
    !userSelections || isEditing ? renderSelectionForm() : null
) : (
    <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-2xl font-bold mb-6">All Selections</h2>
        <div className="overflow-x-auto">
            {/* Desktop view */}
            <table className="hidden md:table w-full">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</th>
                        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Selections</th>
                        <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Score</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {allSelections.map((userSelection, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                            <td className="py-4 px-4">{userSelection.user.displayName}</td>
                            <td className="py-4 px-4">
                                <div className="flex flex-wrap gap-2">
                                    {userSelection.selections.map((selection, selIndex) => (
                                        <span
                                            key={selIndex}
                                            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                                selection.result === 'Win' ? 'bg-green-100 text-green-800' :
                                                selection.result === 'Loss' ? 'bg-red-100 text-red-800' :
                                                selection.result === 'Push' ? 'bg-yellow-100 text-yellow-800' :
                                                'bg-gray-100 text-gray-800'
                                            }`}
                                        >
                                            {selection.selectedTeam} ({formatHandicap(selection.handicap)})
                                        </span>
                                    ))}
                                </div>
                            </td>
                            <td className="py-4 px-4">{userSelection.score}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Mobile view */}
            <div className="md:hidden divide-y divide-gray-200">
                {allSelections.map((userSelection, index) => (
                    <div key={index} className="py-2.5">
                        <div className="flex justify-between items-start mb-1.5">
                            <span className="text-sm font-medium">{userSelection.user.displayName}</span>
                            <div className="text-xs text-gray-500 ml-2">#{index + 1}</div>
                        </div>
                        <div className="flex flex-wrap gap-1">
                            {userSelection.selections.map((selection, selIndex) => (
                                <span
                                    key={selIndex}
                                    className={`inline-flex items-center px-1.5 py-0.5 rounded text-xs font-medium ${
                                        selection.result === 'Win' ? 'bg-green-100 text-green-800' :
                                        selection.result === 'Loss' ? 'bg-red-100 text-red-800' :
                                        selection.result === 'Push' ? 'bg-yellow-100 text-yellow-800' :
                                        'bg-gray-100 text-gray-800'
                                    }`}
                                >
                                    {selection.selectedTeam} ({formatHandicap(selection.handicap)})
                                </span>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
)}
            </div>
        );
    };

    const renderSelectionForm = () => {
        const DesktopForm = () => (
            <div className="hidden md:block bg-white rounded-lg shadow-sm p-6 relative">
                <div className="mb-6">
                    <h2 className="text-2xl font-bold mb-2">Make Your Selections</h2>
                    <p className="text-gray-600">Click on a team to select them. Your selections will be highlighted in blue.</p>
                    <div className="flex items-center mt-2 text-sm text-gray-500">
                        <span className="inline-block w-3 h-3 rounded-full bg-indigo-100 border-2 border-indigo-500 mr-2"></span>
                        Selected
                        <span className="inline-block w-3 h-3 rounded-full bg-white border-2 border-gray-300 mx-2 ml-4"></span>
                        Available
                    </div>
                </div>

                <form onSubmit={handleFormSubmit}>
                    <div className="sticky top-0 z-10 bg-white border-b border-gray-200 py-3 mb-6">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="font-medium text-gray-700">Selections: {selections.length}/5</div>
                                {selections.length > 0 && (
                                    <div className="ml-4 text-sm text-gray-500">
                                        {5 - selections.length} remaining
                                    </div>
                                )}
                            </div>
                            <button
                                type="submit"
                                disabled={selections.length !== 5}
                                className="bg-indigo-600 text-white px-6 py-2 rounded-lg font-medium hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed"
                                >
                                    {userSelections ? 'Update Selections' : 'Submit Selections'}
                                </button>
                            </div>
                        </div>
    
                        <div className="space-y-4">
                            {matches.map((match) => {
                                const existingSelection = selections.find(s => s.matchId === match._id);
                                return (
                                    <div key={match._id} className="grid grid-cols-12 gap-4 items-center bg-gray-50 p-4 rounded-lg hover:bg-gray-100">
                                        <div className="col-span-5">
                                            <button
                                                type="button"
                                                onClick={() => handleSelectionChange(match._id, match.awayTeam, match.awayHandicap)}
                                                className={`w-full p-3 text-left border rounded-lg ${
                                                    existingSelection?.selectedTeam === match.awayTeam
                                                        ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                                                        : 'bg-white hover:border-indigo-500'
                                                }`}
                                            >
                                                {match.awayTeam} ({formatHandicap(match.awayHandicap)})
                                            </button>
                                        </div>
                                        <span className="col-span-2 text-center">@</span>
                                        <div className="col-span-5">
                                            <button
                                                type="button"
                                                onClick={() => handleSelectionChange(match._id, match.homeTeam, match.homeHandicap)}
                                                className={`w-full p-3 text-left border rounded-lg ${
                                                    existingSelection?.selectedTeam === match.homeTeam
                                                        ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                                                        : 'bg-white hover:border-indigo-500'
                                                }`}
                                            >
                                                {match.homeTeam} ({formatHandicap(match.homeHandicap)})
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </form>
                </div>
            );
    
            const MobileForm = () => (
                <div className="md:hidden bg-white">
                    <div className="fixed top-0 left-0 right-0 z-20 bg-white border-b border-gray-200 px-4 py-3 pb-safe-area-inset-bottom">
                        <div className="flex items-center justify-between">
                            <div className="text-sm text-gray-600">
                                {selections.length}/5 selections made
                            </div>
                            <button
                                type="submit"
                                form="mobile-selection-form"
                                disabled={selections.length !== 5}
                                className="bg-indigo-600 text-white px-4 py-2 rounded-full text-sm font-medium disabled:opacity-50"
                            >
                                {selections.length === 5 ? 'Submit' : `${5 - selections.length} more needed`}
                            </button>
                        </div>
                    </div>
    
                    <div className="pt-14 pb-16 px-4">
                        <form id="mobile-selection-form" onSubmit={handleFormSubmit} className="space-y-3">
                            {matches.map((match) => {
                                const existingSelection = selections.find(s => s.matchId === match._id);
                                return (
                                    <div key={match._id} className="border rounded-xl overflow-hidden">
                                        <div className="text-xs text-gray-500 px-3 py-1 bg-gray-50 border-b">
                                            Match {match.matchNumber}
                                        </div>
                                        <div className="p-3 space-y-2">
                                            <button
                                                type="button"
                                                onClick={() => handleSelectionChange(match._id, match.awayTeam, match.awayHandicap)}
                                                className={`w-full p-3 rounded-lg flex items-center ${
                                                    existingSelection?.selectedTeam === match.awayTeam
                                                        ? 'bg-indigo-50 text-indigo-700'
                                                        : 'bg-gray-50'
                                                }`}
                                            >
                                                <div className={`w-4 h-4 rounded-full border-2 mr-3 ${
                                                    existingSelection?.selectedTeam === match.awayTeam
                                                        ? 'border-indigo-500 bg-indigo-100'
                                                        : 'border-gray-300'
                                                }`} />
                                                <div>
                                                    <div className="font-medium">{match.awayTeam}</div>
                                                    <div className="text-sm text-gray-500">
                                                        {formatHandicap(match.awayHandicap)}
                                                    </div>
                                                </div>
                                            </button>
    
                                            <button
                                                type="button"
                                                onClick={() => handleSelectionChange(match._id, match.homeTeam, match.homeHandicap)}
                                                className={`w-full p-3 rounded-lg flex items-center ${
                                                    existingSelection?.selectedTeam === match.homeTeam
                                                        ? 'bg-indigo-50 text-indigo-700'
                                                        : 'bg-gray-50'
                                                }`}
                                            >
                                                <div className={`w-4 h-4 rounded-full border-2 mr-3 ${
                                                    existingSelection?.selectedTeam === match.homeTeam
                                                        ? 'border-indigo-500 bg-indigo-100'
                                                        : 'border-gray-300'
                                                }`} />
                                                <div>
                                                    <div className="font-medium">{match.homeTeam}</div>
                                                    <div className="text-sm text-gray-500">
                                                        {formatHandicap(match.homeHandicap)}
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </form>
                    </div>
                </div>
            );
    
            return (
                <>
                    <DesktopForm />
                    <MobileForm />
                </>
            );
        };
    
        const renderLeaderboard = () => (
            <div className="bg-white rounded-lg shadow-sm p-6">
                <h2 className="text-2xl font-bold mb-6">Leaderboard</h2>
                <div className="space-y-8">
                    <div>
                        <h3 className="text-lg font-semibold mb-4">Overall Standings</h3>
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="py-3 px-4 text-left">Rank</th>
                                        <th className="py-3 px-4 text-left">User</th>
                                        <th className="py-3 px-4 text-left">Total Score</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {leaderboardData.cumulativeLeaderboard.map((entry, index) => (
                                        <tr key={index} className="hover:bg-gray-50">
                                            <td className="py-4 px-4">{index + 1}</td>
                                            <td className="py-4 px-4">{entry.displayName}</td>
                                            <td className="py-4 px-4">{entry.totalScore}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
    
                    <div>
                        <h3 className="text-lg font-semibold mb-4">Weekly Breakdown</h3>
                        <div className="overflow-x-auto">
                            <table className="w-full">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="py-3 px-4 text-left">User</th>
                                        {leaderboardData.gameweeks.map(week => (
                                            <th key={week} className="py-3 px-4 text-left">{week}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {leaderboardData.gameweekBreakdown.map((user, index) => (
                                        <tr key={index} className="hover:bg-gray-50">
                                            <td className="py-4 px-4">{user.displayName}</td>
                                            {leaderboardData.gameweeks.map(week => (
                                                <td key={week} className="py-4 px-4">
                                                    {user.scores[week] || '-'}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    
        if (isLoading) {
            return (
                <div className="min-h-screen flex items-center justify-center">
                    <LoadingSpinner />
                </div>
            );
        }
    
        return (
            <div className="min-h-screen bg-gray-50">
                {renderHeader()}
                
                <main className="container mx-auto px-4 py-2 sm:py-8">
                    <div className="max-w-4xl mx-auto space-y-4 sm:space-y-6">
                        {error && (
                            <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
                                {error}
                            </div>
                        )}
                        
                        {successMessage && (
                            <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg">
                                {successMessage}
                            </div>
                        )}
    
                        {renderTabs()}
    
                        <div className="mt-6">
                            {activeTab === 'selections' ? renderSelections() : renderLeaderboard()}
                        </div>
                    </div>
                </main>
            </div>
        );
    };
    
    export default NFLSCPage;