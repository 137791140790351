import React, { useState, useEffect } from 'react';
import { Users, Plus } from 'lucide-react';
import { golfService } from '../../../api/services';

const LeagueEnrollment = ({ userSelections }) => {
    const [leagues, setLeagues] = useState([]);
    const [userLeague, setUserLeague] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [joiningLeague, setJoiningLeague] = useState(false);
  
    const loadLeagues = async () => {
      try {
        console.log('Fetching leagues...');
        const response = await golfService.getAvailableLeagues();
        console.log('Leagues response:', response);
        
        setLeagues(response.leagues || []);
        
        // Find if user is in any league
        const userLeagueData = response.leagues?.find(league => 
          league.members.some(member => member.user === response.currentUserId)
        );
        console.log('User league data:', userLeagueData);
        
        setUserLeague(userLeagueData);
      } catch (err) {
        console.error('Error loading leagues:', err);
        setError('Failed to load leagues');
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      if (userSelections) {
        loadLeagues();
      }
    }, [userSelections]);
  
    const handleJoinLeague = async (leagueId) => {
      try {
        setJoiningLeague(true);
        setError(null);
        
        await golfService.joinLeague(leagueId);
        
        // Reload leagues immediately after joining
        await loadLeagues();
      } catch (err) {
        console.error('Error joining league:', err);
        setError('Failed to join league');
      } finally {
        setJoiningLeague(false);
      }
    };

  console.log('LeagueEnrollment render state:', {
    loading,
    leagues: leagues.length,
    userLeague,
    error,
    joiningLeague
  });

  if (loading) {
    return (
      <div className="mt-6 p-4 bg-gray-50 rounded-lg">
        Loading leagues...
      </div>
    );
  }

  if (userLeague) {
    return (
      <div className="mt-6 p-4 bg-gray-50 rounded-lg">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Users className="w-5 h-5 text-blue-500 mr-2" />
            <div>
              <h3 className="font-medium">League Membership</h3>
              <p className="text-sm text-gray-600">You're enrolled in {userLeague.name}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-6">
      <h3 className="font-medium mb-3">Available Leagues</h3>
      <div className="space-y-2">
        {error && (
          <div className="text-red-600 text-sm p-2 bg-red-50 rounded-lg">
            {error}
          </div>
        )}
        
        {leagues.map(league => (
          <div 
            key={league._id}
            className="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
          >
            <div>
              <span className="font-medium">{league.name}</span>
              <div className="text-sm text-gray-500">
                {league.members.length} members
              </div>
            </div>
            <button
              onClick={() => handleJoinLeague(league._id)}
              disabled={joiningLeague}
              className="flex items-center px-3 py-1 text-sm font-medium text-blue-600 
                border border-blue-600 rounded-lg hover:bg-blue-50 
                disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Plus className="w-4 h-4 mr-1" />
              {joiningLeague ? 'Joining...' : 'Join League'}
            </button>
          </div>
        ))}
        
        {leagues.length === 0 && (
          <p className="text-gray-600 text-sm">
            No leagues available for this tournament.
          </p>
        )}
      </div>
    </div>
  );
};

export default LeagueEnrollment;