import { useState, useEffect } from 'react';
import { golfAdminService } from '../../api/services';
import useGolfAdminBase from './useGolfAdminBase';

export const useTournamentManagement = () => {
  const { loading, error, syncMessage, setLoading, setError, setSyncMessage, handleError } = useGolfAdminBase();
  const [currentGame, setCurrentGame] = useState(null);

  useEffect(() => {
    fetchCurrentGame();
  }, []);

  const fetchCurrentGame = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await golfAdminService.getCurrentGame();
      console.log('Tournament data received:', response);
      
      if (!response || !response.currentGame) {
        setCurrentGame(null);
        return;
      }
      
      setCurrentGame(response.currentGame);
    } catch (err) {
      handleError(err, 'Failed to fetch current tournament');
    } finally {
      setLoading(false);
    }
  };

  const createTournament = async (tournamentData) => {
    try {
      setSyncMessage('Creating new tournament...');
      const response = await golfAdminService.createGame(tournamentData);
      await fetchCurrentGame();
      setSyncMessage('Tournament created successfully!');
      return response;
    } catch (err) {
      handleError(err, 'Failed to create tournament');
    }
  };

  const updateTournament = async (tournamentId, updateData) => {
    try {
      setSyncMessage('Updating tournament...');
      const response = await golfAdminService.updateGame(tournamentId, updateData);
      await fetchCurrentGame();
      setSyncMessage('Tournament updated successfully!');
      return response;
    } catch (err) {
      handleError(err, 'Failed to update tournament');
    }
  };

  return {
    currentGame,
    loading,
    error,
    syncMessage,
    createTournament,
    updateTournament,
    refreshTournament: fetchCurrentGame
  };
};

export default useTournamentManagement;