export const layout = {
    container: 'container mx-auto px-4',
    spacing: {
      section: 'py-8',
      nav: 'py-4',
      content: 'py-6',
      footer: {
        wrapper: 'py-8',
        section: 'space-y-2',
        divider: 'mt-8 pt-8'
      }
    },
    colors: {
      primary: {
        main: 'bg-purple-800',
        hover: 'hover:bg-purple-700',
        active: 'bg-purple-700',
        text: 'text-purple-200'
      },
      background: 'bg-gray-50',
      footer: {
        text: 'text-white/80',
        textHover: 'hover:text-white',
        border: 'border-white/10'
      }
    }
  };