import React from 'react';
import { useFeatureFlags } from '../../../context/FeatureFlagsContext';
import GameCard from '../../common/GameCard';

const GameSection = ({ currentGame }) => {
  const { SHOW_FOOTBALL, SHOW_NFLSC, isMainSite } = useFeatureFlags();

  const games = [
    SHOW_FOOTBALL && {
      title: "Football - 5 Scorers",
      deadline: currentGame?.deadline,
      links: [{ to: "/football", text: "Play Game" }]
    },
    isMainSite && SHOW_NFLSC && {
      title: "NFL Super Contest",
      links: [{ to: "/nfl-sc", text: "Play Game" }]
    }
  ].filter(Boolean);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {games.map((game, index) => (
        <div key={index} className="w-full">
          <GameCard {...game} className="h-full" />
        </div>
      ))}
    </div>
  );
};

export default GameSection;