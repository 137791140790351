import React, { useState } from 'react';
import ErrorBoundary from '../components/common/ErrorBoundary';
import { useFeatureFlags } from '../context/FeatureFlagsContext';

// Import the index files from each sport's admin folder
import FootballAdmin from '../components/admin/FootballAdmin';  // Keep old path for now
import NFLAdmin from '../components/admin/NFLAdmin';           // Keep old path for now
import NFLSCAdmin from '../components/admin/NFLSCAdmin';       // Keep old path for now
import GolfAdmin from '../components/admin/golf';              // New import path

function Admin() {
  const [activeSport, setActiveSport] = useState('football');
  const { SHOW_GOLF } = useFeatureFlags();

  const sports = [
    { id: 'football', name: 'Football', Component: FootballAdmin },
    { id: 'nfl', name: 'NFL', Component: NFLAdmin },
    { id: 'nfl-sc', name: 'NFL Supercontest', Component: NFLSCAdmin },
    { id: 'golf', name: 'Golf', Component: GolfAdmin, flag: SHOW_GOLF }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Main Navigation */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4">
          <nav className="flex space-x-8">
            {sports
              .filter(sport => !sport.flag || sport.flag)
              .map(sport => (
                <button
                  key={sport.id}
                  onClick={() => setActiveSport(sport.id)}
                  className={`py-4 px-1 border-b-2 font-medium text-sm
                    ${activeSport === sport.id
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    } transition-colors duration-200`}
                >
                  {sport.name}
                </button>
              ))}
          </nav>
        </div>
      </div>

      {/* Content Area */}
      <div className="max-w-7xl mx-auto px-4 py-6">
        <ErrorBoundary>
          {sports
            .filter(sport => !sport.flag || sport.flag)
            .map(sport => (
              activeSport === sport.id && (
                <div key={sport.id} className="mb-8">
                  <sport.Component />
                </div>
              )
            ))}
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default Admin;