import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, Edit, ChevronDown, Users, Clock, Trophy, RefreshCcw } from 'lucide-react';
import { useAuth } from '../../AuthContext';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { golfService } from '../../api/services';
import TournamentLeaderboard from '../../components/golf/TournamentLeaderboard';
import PlayerSelectionGrid from '../../components/golf/PlayerSelectionGrid';
import GameLeaderboard from '../../components/golf/GameLeaderboard';
import LeagueEnrollment from '../../components/golf/LeagueEnrollment';
import LeagueLeaderboard from '../../components/golf/LeagueLeaderboard';

const GolfTournamentPage = () => {
  const [currentGame, setCurrentGame] = useState(null);
  const [leaderboard, setLeaderboard] = useState([]);
  const [lastUpdate, setLastUpdate] = useState('Never');
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [userSelections, setUserSelections] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoadingSelections, setIsLoadingSelections] = useState(false);
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);
  const [gameLeaderboard, setGameLeaderboard] = useState([]);
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState(() => {
    // Default to tournament if no user is logged in
    if (!user) return 'tournament';
    // Set initial tab based on deadline status
    return isDeadlinePassed ? 'myleagues' : 'selections';
  });

// Initial data load effect
const loadData = useCallback(async () => {
    try {
      console.log('Starting data load...', { user: !!user });
      setIsLoading(true);
      
      const gameData = await golfService.getCurrentGame();
      console.log('Game data:', gameData);
      setCurrentGame(gameData);
      
      if (gameData) {
        // Check both deadline and status
        const now = new Date();
        const deadline = new Date(gameData.deadline);
        const deadlinePassed = now > deadline || gameData.status === 'active';
        setIsDeadlinePassed(deadlinePassed);
  
        console.log('Game state:', {
          deadline,
          now,
          deadlinePassed,
          status: gameData.status
        });
  
        const [leaderboardData, playersData, userSelectionsData, gameLeaderboardData] = await Promise.all([
          golfService.getTournamentLeaderboard(),
          golfService.getPlayers(),
          golfService.getMySelections(),
          deadlinePassed ? golfService.getGameLeaderboard() : Promise.resolve([])
        ]);
        
        console.log('Data loaded:', {
          leaderboard: !!leaderboardData,
          players: playersData?.length || 0,
          userSelections: userSelectionsData,
          gameLeaderboard: gameLeaderboardData?.length || 0
        });
        
        const playersArray = Array.isArray(leaderboardData) ? leaderboardData : leaderboardData?.players || [];
        setLeaderboard(playersArray);
        setLastUpdate(leaderboardData?.lastUpdated || null);
        setAvailablePlayers(playersData || []);
        setGameLeaderboard(gameLeaderboardData || []);
        
        if (userSelectionsData?.selections?.length > 0) {
          setUserSelections(userSelectionsData);
        } else {
          setUserSelections(null);
        }
        
        setIsEditing(false);
      }
    } catch (err) {
      console.error('Error loading data:', err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, [user]); // Add other dependencies if needed
  
  // Initial data load effect
  useEffect(() => {
    loadData();
  }, [loadData]);
  
  // Deadline check effect
  useEffect(() => {
    if (currentGame?.deadline) {
      const checkDeadline = () => {
        const isPassed = new Date() > new Date(currentGame.deadline);
        setIsDeadlinePassed(isPassed);
        
        if (isPassed) {
          golfService.getGameLeaderboard()
            .then(data => setGameLeaderboard(data || []))
            .catch(err => console.error('Error fetching game leaderboard:', err));
        }
      };
  
      checkDeadline();
      const interval = setInterval(checkDeadline, 60000);
      return () => clearInterval(interval);
    }
  }, [currentGame]);
  
  // Debug effect for selected players
  useEffect(() => {
    console.log('Selected Players State:', {
      selectedPlayers,
      hasIds: selectedPlayers?.every(p => p?._id),
      length: selectedPlayers?.length
    });
  }, [selectedPlayers]);
  
  // Tournament leaderboard polling effect
  useEffect(() => {
    if (activeTab === 'tournament') {
      const tournamentPolling = setInterval(async () => {
        try {
          console.log('Polling tournament leaderboard...');
          const leaderboardData = await golfService.getTournamentLeaderboard();
          console.log('Updated tournament data:', {
            dataReceived: !!leaderboardData,
            playerCount: leaderboardData?.length || 0
          });
          
          const playersArray = Array.isArray(leaderboardData) ? 
            leaderboardData : leaderboardData?.players || [];
          setLeaderboard(playersArray);
          setLastUpdate(new Date().toISOString());
        } catch (err) {
          console.error('Error polling tournament data:', err);
        }
      }, 60000);
  
      return () => clearInterval(tournamentPolling);
    }
  }, [activeTab]); // Only re-setup polling when tab changes

  useEffect(() => {
    if (user) {
      setActiveTab(isDeadlinePassed ? 'myleagues' : 'selections');
    }
  }, [isDeadlinePassed, user]);

  const handleSubmit = async (selectedPlayers) => {
    try {
      console.log('Submitting selections:', selectedPlayers);
      setIsLoadingSelections(true);
      
      // Validate selections
      if (!selectedPlayers || selectedPlayers.length !== 3) {
        throw new Error('Please select exactly 3 players');
      }
  
      if (!selectedPlayers.every(p => p && p._id)) {
        throw new Error('Invalid player data');
      }
  
      // Extract just the IDs
      const playerIds = selectedPlayers.map(player => player._id);
      console.log('Player IDs to submit:', playerIds);
      
      // Submit just the IDs
      await golfService.submitSelections(playerIds);
      
      // Immediately fetch updated selections
      const updatedSelections = await golfService.getMySelections();
      console.log('Got updated selections:', updatedSelections);
      
      setUserSelections(updatedSelections);
      setSuccessMessage('Your selections have been submitted successfully!');
      setIsEditing(false);
      setError(null);
      
      // Reset selection state
      setSelectedPlayers([]);
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError(err.message);
    } finally {
      setIsLoadingSelections(false);
    }
  };
  
  const handleEdit = () => {
    setIsEditing(true);
    setSuccessMessage(null);
    setError(null);
    
    // Map existing selections to available players
    if (userSelections?.selections) {
      console.log('Editing selections:', userSelections.selections);
      const currentSelections = userSelections.selections.map(selection => {
        const fullPlayer = availablePlayers.find(p => p._id === selection._id);
        return fullPlayer;
      }).filter(Boolean);
      
      console.log('Setting selected players for edit:', currentSelections);
      setSelectedPlayers(currentSelections);
    }
  };

  const renderHeader = () => (
    <div className="bg-blue-600 px-4 py-3">
      <div className="flex justify-between items-start">
        <div>
          <div className="flex items-center gap-2">
            <h1 className="text-xl font-bold text-white">{currentGame?.tournamentName}</h1>
            {currentGame?.prize && (
              <div className="flex items-center text-white">
                <Trophy className="w-4 h-4 mr-1" />
                <span className="font-medium">€{currentGame.prize}</span>
              </div>
            )}
          </div>
          <div className="flex items-center gap-2 text-sm text-white/90 mt-1">
            <span className="flex items-center">
              <Calendar className="w-3.5 h-3.5 mr-1" />
              {currentGame?.deadline && new Date(currentGame.deadline).toLocaleString(undefined, {
                day: 'numeric',
                month: 'short',
                hour: '2-digit',
                minute: '2-digit'
              })}
            </span>
            <span className="text-white/60">•</span>
            <span>{currentGame?.status}</span>
            <span className="text-white/60">•</span>
            <span className="flex items-center">
              <Users className="w-3.5 h-3.5 mr-1" />
              {leaderboard.length} Players
            </span>
            <span className="text-white/60">•</span>
            <span className="flex items-center">
              <RefreshCcw className="w-3.5 h-3.5 mr-1" />
              Last update: {lastUpdate}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderTabs = () => (
    <div className="border-b border-gray-200">
      <div className="max-w-4xl mx-auto">
        <nav className="flex space-x-8" aria-label="Tabs">
          {user ? (
            <>
              <button
                onClick={() => setActiveTab('selections')}
                className={`py-4 px-1 border-b-2 font-medium text-sm
                  ${activeTab === 'selections'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
              >
                My Selections
              </button>
              <button
                onClick={() => setActiveTab('myleagues')}
                className={`py-4 px-1 border-b-2 font-medium text-sm
                  ${activeTab === 'myleagues'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
              >
                My Leagues
              </button>
              <button
                onClick={() => setActiveTab('leaderboard')}
                className={`py-4 px-1 border-b-2 font-medium text-sm
                  ${activeTab === 'leaderboard'
                    ? 'border-blue-500 text-blue-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
              >
                Game Leaderboard
              </button>
            </>
          ) : null}
          <button
            onClick={() => {
              console.log('Switching to tournament tab');
              setActiveTab('tournament');
            }}
            className={`py-4 px-1 border-b-2 font-medium text-sm
              ${activeTab === 'tournament'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
          >
            Tournament Leaderboard
          </button>
        </nav>
      </div>
    </div>
  );

  const renderTournamentLeaderboard = () => {
    console.log('Rendering tournament leaderboard:', {
      hasData: leaderboard?.length > 0,
      dataCount: leaderboard?.length,
      lastUpdate
    });
  
    const handleRefresh = async () => {
      try {
        const leaderboardData = await golfService.getTournamentLeaderboard();
        const playersArray = Array.isArray(leaderboardData) ? leaderboardData : leaderboardData?.players || [];
        setLeaderboard(playersArray);
        setLastUpdate(new Date().toISOString());
      } catch (err) {
        console.error('Error refreshing leaderboard:', err);
        setError('Failed to refresh leaderboard');
      }
    };
  
    if (!leaderboard || leaderboard.length === 0) {
      return (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <p className="text-gray-600">
            No tournament data available. 
            <button 
              onClick={handleRefresh}
              className="ml-2 text-blue-600 hover:text-blue-800"
            >
              Click to refresh
            </button>
          </p>
        </div>
      );
    }
  
    return (
      <TournamentLeaderboard 
        leaderboard={leaderboard}
        lastUpdate={lastUpdate}
        isLoading={isLoading}
        onRefresh={handleRefresh}
      />
    );
  };

  const renderGameLeaderboard = () => {
    const handleRefresh = async () => {
      try {
        const gameLeaderboardData = await golfService.getGameLeaderboard();
        setGameLeaderboard(gameLeaderboardData || []);
      } catch (err) {
        console.error('Error refreshing game leaderboard:', err);
        setError('Failed to refresh game leaderboard');
      }
    };
  
    return (
      <GameLeaderboard 
        leaderboard={gameLeaderboard}
        isLoading={isLoading}
        onRefresh={handleRefresh}
        isDeadlinePassed={isDeadlinePassed}
      />
    );
  };

  const renderSelectionForm = () => (
    <PlayerSelectionGrid
      availablePlayers={availablePlayers}
      onSubmit={handleSubmit}
      initialSelections={userSelections?.selections}
      selectedPlayers={selectedPlayers}
      setSelectedPlayers={setSelectedPlayers}
      isEditing={isEditing}
    />
  );

  const renderSelectionsContent = () => {
    if (!user) {
      return (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <p className="text-lg">
            Please <a href="/login" className="text-blue-600 hover:underline">log in</a> to make or view your selections.
          </p>
        </div>
      );
    }
  
    if (isDeadlinePassed) {
      return (
        <div className="bg-gradient-to-br from-white to-blue-50 rounded-lg shadow-sm p-6">
          <h2 className="text-2xl font-bold mb-6">My Selections</h2>
          {userSelections?.selections?.length > 0 ? (
            <>
              <div className="space-y-4">
                {userSelections.selections.map((selection, index) => (
                  <div key={selection._id} className="p-4 bg-blue-500/5 rounded-lg">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <div className="w-8 h-8 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center font-semibold mr-3">
                          {index + 1}
                        </div>
                        <div>
                          <div className="font-medium text-gray-900">{selection.name}</div>
                          <div className="text-sm text-gray-600">Odds: {selection.odds}/1</div>
                        </div>
                      </div>
                      <div className="text-right">
                        <span className={`text-xl font-bold ${
                          selection.tournamentScore === "CUT" ? "text-red-500" :
                          selection.tournamentScore?.startsWith("-") ? "text-green-600" : "text-red-600"
                        }`}>
                          {selection.tournamentScore || '-'}
                        </span>
                        <div className="text-sm text-gray-500">
                          Pos: {selection.tournamentPosition || '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                
                {userSelections.leagues && userSelections.leagues.length > 0 && (
                  <div className="border-t mt-6 pt-4">
                    <div className="flex items-center space-x-2 text-gray-600">
                      <Users className="w-4 h-4" />
                      <span>You're enrolled in {userSelections.leagues.join(', ')}</span>
                    </div>
                  </div>
                )}
  
                <LeagueEnrollment userSelections={userSelections} />
              </div>
            </>
          ) : (
            <p className="text-gray-600">
              The deadline has passed and no selections were made for this tournament.
            </p>
          )}
        </div>
      );
    }
  
    return (
      <>
        {successMessage && (
          <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg mb-6">
            {successMessage}
          </div>
        )}
        
        {userSelections?.selections?.length > 0 && !isEditing ? (
          <div className="bg-gradient-to-br from-white to-blue-50 rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold">My Selections</h2>
              <button
                onClick={handleEdit}
                className="flex items-center px-4 py-2 text-sm font-medium text-blue-600 
                  border border-blue-600 rounded-lg hover:bg-blue-50 transition-colors duration-300"
              >
                <Edit className="w-4 h-4 mr-2" />
                Edit Selections
              </button>
            </div>
            <div className="space-y-4">
              {userSelections.selections.map((selection, index) => (
                <div
                  key={selection._id || index}
                  className="p-4 bg-blue-500/5 rounded-lg"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="w-8 h-8 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center font-semibold mr-3">
                        {index + 1}
                      </div>
                      <div>
                        <div className="font-medium text-gray-900">{selection.name}</div>
                        <div className="text-sm text-gray-600">Odds: {selection.odds}/1</div>
                      </div>
                    </div>
                    <div className="text-right text-sm text-gray-500">
                      Score: {selection.tournamentScore || '-'}<br />
                      Position: {selection.tournamentPosition || '-'}
                    </div>
                  </div>
                </div>
              ))}
              
              <div className="mt-6 p-4 bg-blue-500/5 rounded-lg">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium text-gray-600">Combined Odds:</span>
                  <span className="text-lg font-bold">
                    {userSelections.selections.reduce((total, sel) => total + sel.odds, 0).toFixed(1)}/1
                  </span>
                </div>
              </div>
  
              <LeagueEnrollment userSelections={userSelections} />
            </div>
          </div>
        ) : (
          <div className="bg-gradient-to-br from-white to-blue-50 rounded-lg shadow-sm p-6">
            <h2 className="text-2xl font-bold mb-4">
              {isEditing ? 'Edit Your Selections' : 'Make Your Selections'}
            </h2>
            {renderSelectionForm()}
          </div>
        )}
      </>
    );
  };

 if (isLoading) {
   return (
     <div className="min-h-screen flex items-center justify-center">
       <LoadingSpinner />
     </div>
   );
 }

 return (
    <div className="min-h-screen bg-gray-50">
      {currentGame && renderHeader()}
      {renderTabs()}
  
      <main className="container mx-auto px-4 py-8">
        <div className="max-w-6xl mx-auto space-y-6">
          {error && (
            <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg">
              {error}
            </div>
          )}
  
          {successMessage && (
            <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg">
              {successMessage}
            </div>
          )}
  
            {!currentGame ? (
            <div className="bg-white rounded-lg shadow-sm p-6">
                <p className="text-lg text-gray-600">
                There is no active tournament at the moment. Please check back later.
                </p>
            </div>
            ) : (
            <>
                {activeTab === 'tournament' && renderTournamentLeaderboard()}
                {activeTab === 'selections' && renderSelectionsContent()}
                {activeTab === 'myleagues' && <LeagueLeaderboard isDeadlinePassed={isDeadlinePassed} />}
                {activeTab === 'leaderboard' && renderGameLeaderboard()}
            </>
            )}
        </div>
      </main>
    </div>
  );
}

export default GolfTournamentPage;