import { useState, useEffect } from 'react';
import { golfAdminService } from '../../api/services';
import useGolfAdminBase from './useGolfAdminBase';

export const usePlayerManagement = () => {
  const { loading, error, syncMessage, setLoading, setError, setSyncMessage, handleError } = useGolfAdminBase();
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    fetchPlayers();
  }, []);

  const fetchPlayers = async () => {
    try {
      setLoading(true);
      const response = await golfAdminService.getAvailablePlayers();
      setPlayers(response);
    } catch (err) {
      handleError(err, 'Failed to fetch players');
    } finally {
      setLoading(false);
    }
  };

  const syncPlayers = async () => {
    try {
      setSyncMessage('Syncing players from sheets...');
      const response = await golfAdminService.syncPlayers();
      await fetchPlayers();
      setSyncMessage('Players synced successfully!');
      return response;
    } catch (err) {
      handleError(err, 'Failed to sync players');
    }
  };

  const updatePlayerOdds = async (playerId, odds) => {
    try {
      setSyncMessage('Updating player odds...');
      await golfAdminService.updatePlayerOdds(playerId, odds);
      await fetchPlayers();
      setSyncMessage('Odds updated successfully!');
    } catch (err) {
      handleError(err, 'Failed to update player odds');
    }
  };

  return {
    players,
    loading,
    error,
    syncMessage,
    syncPlayers,
    updatePlayerOdds,
    refreshPlayers: fetchPlayers
  };
};

export default usePlayerManagement;