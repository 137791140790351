import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HeroBanner = ({ banners = [] }) => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);

  const defaultBanners = [
    {
      title: "5 Goal Scorers",
      description: "Pick 5 players to score this week",
      cta: "Play Now",
      route: "/football",
      bgColor: "emerald"
    },
    {
      title: "NFL Super Contest",
      description: "Make your weekly NFL picks",
      cta: "Join Contest",
      route: "/nfl-sc",
      bgColor: "purple"
    }
  ];

  const displayBanners = banners.length > 0 ? banners : defaultBanners;

  useEffect(() => {
    if (displayBanners.length > 1) {
      const timer = setInterval(() => {
        setCurrentSlide((prev) => (prev + 1) % displayBanners.length);
      }, 5000);

      return () => clearInterval(timer);
    }
  }, [displayBanners.length]);

  return (
    <div className="relative h-[250px] md:h-[300px] bg-gradient-to-r from-indigo-600 via-purple-600 to-purple-800 rounded-lg shadow-xl overflow-hidden">
      {/* Remove mx-4 mt-4 from the className above */}
      
      {/* Animated background pattern */}
      <div className="absolute inset-0 opacity-10">
        <svg width="100%" height="100%">
          <defs>
            <pattern 
              id="hero-pattern" 
              x="0" 
              y="0" 
              width="40" 
              height="40" 
              patternUnits="userSpaceOnUse"
              patternTransform="rotate(30)"
            >
              <circle cx="20" cy="20" r="15" stroke="currentColor" strokeWidth="0.5" fill="none" />
              <circle cx="20" cy="20" r="10" stroke="currentColor" strokeWidth="0.5" fill="none" />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#hero-pattern)"/>
        </svg>
      </div>

      {/* Decorative elements */}
      <div className="absolute top-0 right-0 w-32 h-32 bg-white/5 rounded-full -translate-y-16 translate-x-16"></div>
      <div className="absolute bottom-0 left-0 w-24 h-24 bg-white/5 rounded-full translate-y-12 -translate-x-12"></div>

      {/* Carousel Content */}
      <div className="relative h-full">
        {displayBanners.map((banner, index) => {
          const imageUrl = banner.image?.url ? `${process.env.REACT_APP_STRAPI_URL}${banner.image.url}` : null;
          
          return (
            <div
              key={index}
              className={`absolute inset-0 flex items-center transition-opacity duration-500
                ${index === currentSlide ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
              style={imageUrl ? {
                backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.7), rgba(0,0,0,0.4)), url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              } : {}}
            >
              <div className="max-w-2xl mx-8"> {/* Added mx-8 for side padding */}
                <h1 className="text-3xl md:text-5xl font-bold mb-2 md:mb-4 text-white tracking-tight">
                  {banner.title}
                </h1>
                <p className="text-lg md:text-2xl mb-4 md:mb-8 text-white/90 leading-relaxed">
                  {banner.description}
                </p>
                {banner.cta && (
                  <button
                    onClick={() => navigate(banner.route)}
                    className="bg-white text-purple-700 hover:bg-purple-50 px-6 md:px-8 py-3 md:py-4 
                             rounded-lg text-base md:text-xl font-semibold transition-all duration-300 
                             hover:scale-105 hover:shadow-lg"
                  >
                    {banner.cta}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Carousel Controls */}
      {displayBanners.length > 1 && (
        <div className="absolute bottom-2 md:bottom-6 left-0 right-0">
          <div className="mx-8"> {/* Added mx-8 to match content padding */}
            <div className="flex space-x-2 md:space-x-3">
              {displayBanners.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  className={`w-2 h-2 md:w-3 md:h-3 rounded-full transition-colors duration-300 
                    ${index === currentSlide ? 'bg-white' : 'bg-white/40 hover:bg-white/60'}`}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroBanner;