import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import { getGameRoutes } from '../../../routes';
import { NavItem, MobileMenu } from './MenuItems';
import { useFeatureFlags } from '../../../context/FeatureFlagsContext';
import { NavigationLink } from './NavigationLink';
import { layout } from '../../../utils/layout';
import { cn } from '../../../utils/cn';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useAuth(); // Removed logout since we're moving it to Profile
  const navigate = useNavigate();
  const flags = useFeatureFlags();
  const gameRoutes = getGameRoutes();
  const availableGames = gameRoutes
    .filter(route => flags[route.feature])
    .map(route => ({ name: route.path.slice(1), path: route.path }));

  return (
    <nav className={layout.colors.primary.main}>
      <div className={cn(layout.container)}>
        <div className={cn('flex justify-between items-center', layout.spacing.nav)}>
          <NavigationLink 
            to="/" 
            className="text-2xl font-bold text-white hover:no-underline"
          >
            {process.env.REACT_APP_HEADER_TITLE || 'Predictions'}
          </NavigationLink>
          
          {/* Desktop Menu */}
          <div className="hidden md:flex items-center space-x-4">
            {availableGames.map(game => (
              <NavigationLink key={game.path} to={game.path}>
                {game.name}
              </NavigationLink>
            ))}
            {user ? (
              <>
                <NavigationLink to="/profile">Profile</NavigationLink>
                {user.isAdmin && <NavigationLink to="/admin">Admin</NavigationLink>}
              </>
            ) : (
              <>
                <NavigationLink to="/login">Login</NavigationLink>
                <NavigationLink to="/register">Register</NavigationLink>
              </>
            )}
          </div>

          {/* Mobile Menu Button */}
          <button 
            className={cn(
              'md:hidden',
              layout.colors.primary.active,
              'px-3 py-2 rounded',
              layout.colors.primary.hover
            )}
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Toggle menu"
            aria-expanded={isOpen}
          >
            Menu
          </button>
        </div>

        {/* Mobile Menu */}
        <div 
          className={cn(
            'md:hidden',
            'transition-all duration-200 ease-in-out',
            isOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          )}
        >
          <div className="py-2">
            {availableGames.map(game => (
              <NavigationLink key={game.path} to={game.path} isMobile>
                {game.name}
              </NavigationLink>
            ))}
            {user ? (
              <>
                <NavigationLink to="/profile" isMobile>Profile</NavigationLink>
                {user.isAdmin && <NavigationLink to="/admin" isMobile>Admin</NavigationLink>}
              </>
            ) : (
              <>
                <NavigationLink to="/login" isMobile>Login</NavigationLink>
                <NavigationLink to="/register" isMobile>Register</NavigationLink>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;