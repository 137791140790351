import BaseApi, { API_ENDPOINTS } from '../../base/baseApi';

class GolfAdminService extends BaseApi {
    async getCurrentGame() {
        try {
            const response = await this.get(API_ENDPOINTS.admin.golf.currentGame);
            console.log('Golf admin service response:', response);
            return response;
        } catch (error) {
            console.error('Error in getCurrentGame:', error);
            throw error;
        }
    }

    async createGame(tournamentData) {
        try {
            console.log('Creating new tournament:', tournamentData);
            const response = await this.post(API_ENDPOINTS.admin.golf.games, tournamentData);
            console.log('Tournament creation response:', response);
            return response;
        } catch (error) {
            console.error('Error creating new tournament:', error);
            throw error;
        }
    }

    async syncPlayers() {
        try {
            const response = await this.post(API_ENDPOINTS.admin.golf.sync);
            console.log('Player sync response:', response);
            return response;
        } catch (error) {
            console.error('Error syncing players:', error);
            throw error;
        }
    }

    async getAvailablePlayers() {
        try {
            const response = await this.get(API_ENDPOINTS.admin.golf.players);
            console.log('Available players response:', response);
            return response;
        } catch (error) {
            console.error('Error getting available players:', error);
            throw error;
        }
    }

    async syncTournament() {
        try {
            const response = await this.post(API_ENDPOINTS.admin.golf.syncTournament);
            console.log('Tournament sync response:', response);
            return response;
        } catch (error) {
            console.error('Error syncing tournament:', error);
            throw error;
        }
    }

    async getPlayerMappings() {
        try {
            const response = await this.get(API_ENDPOINTS.admin.golf.playerMappings);
            return response;
        } catch (error) {
            console.error('Error getting player mappings:', error);
            throw error;
        }
    }

    async addPlayerMapping(canonicalName, variations) {
        try {
            const response = await this.post(API_ENDPOINTS.admin.golf.playerMappings, {
                canonicalName,
                variations
            });
            return response;
        } catch (error) {
            console.error('Error adding player mapping:', error);
            throw error;
        }
    }

    async removePlayerMapping(canonicalName) {
        try {
            const response = await this.delete(API_ENDPOINTS.admin.golf.playerMappings, {
                data: { canonicalName }
            });
            return response;
        } catch (error) {
            console.error('Error removing player mapping:', error);
            throw error;
        }
    }

    async updatePlayerOdds(playerId, odds) {
        try {
            const response = await this.put(
                API_ENDPOINTS.admin.golf.updateOdds(playerId),
                { odds }
            );
            return response;
        } catch (error) {
            console.error('Error updating player odds:', error);
            throw error;
        }
    }

    async createLeague(name) {
        try {
            const response = await this.getCurrentGame();
            console.log('Current game response in createLeague:', response);
            
            if (!response?.currentGame) {
                console.error('No current game data found:', response);
                throw new Error('No active tournament found');
            }
            
            const currentGame = response.currentGame;
            const leagueData = {
                name,
                game: currentGame._id,
                tournamentName: currentGame.name,
                status: 'active'
            };
            
            console.log('About to send league creation request with data:', JSON.stringify(leagueData, null, 2));
            const leagueResponse = await this.post(API_ENDPOINTS.admin.golf.leagues, leagueData);
            console.log('League creation response:', leagueResponse);
            return leagueResponse?.data;
        } catch (error) {
            console.error('Error in createLeague:', error);
            throw error;
        }
    }

    async getLeaguesForGame() {
        try {
            const response = await this.getCurrentGame();
            console.log('Current game in getLeaguesForGame:', response);
            
            if (!response?.data?.currentGame) {
                return [];
            }
            
            const gameId = response.data.currentGame._id;
            console.log('Fetching leagues for game:', gameId);
            
            const leaguesResponse = await this.get(`${API_ENDPOINTS.admin.golf.leagues}?gameId=${gameId}`);
            console.log('Leagues response:', leaguesResponse);
            return leaguesResponse?.data?.data || [];
        } catch (error) {
            console.error('Error getting leagues:', error);
            throw error;
        }
    }
}

const golfAdminService = new GolfAdminService();
console.log('Created golfAdminService instance:', golfAdminService);
console.log('Instance methods:', Object.getOwnPropertyNames(GolfAdminService.prototype));
export { golfAdminService };