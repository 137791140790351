import BaseApi, { API_ENDPOINTS } from '../../base/baseApi';

class GolfService extends BaseApi {
    constructor() {
        super();
        this.lastUpdate = null;
    }

    // Helper functions for data formatting
    formatScore(score) {
        if (!score || score === '-') return '-';
        if (score === 'E') return 'E';
        if (score === 'CUT') return 'CUT';
        if (score === 'WD') return 'WD';
        
        const numScore = parseInt(score);
        if (isNaN(numScore)) return score;
        return numScore > 0 ? `+${numScore}` : numScore.toString();
    }

    formatPosition(pos) {
        if (!pos || pos === '-') return '-';
        return pos;
    }

    getUpdateTime(lastUpdated) {
        if (!lastUpdated) return 'Never';
        const date = new Date(lastUpdated);
        const now = new Date();
        const diff = Math.floor((now - date) / 1000); // seconds

        if (diff < 60) return 'Just now';
        if (diff < 3600) return `${Math.floor(diff / 60)}m ago`;
        if (diff < 86400) return `${Math.floor(diff / 3600)}h ago`;
        return date.toLocaleDateString();
    }

    async getCurrentGame() {
        try {
            const response = await this.get(`${API_ENDPOINTS.golf.currentGame}`);
            return response;
        } catch (error) {
            console.error('Error getting current game:', error);
            throw error;
        }
    }
  
    async getTournamentLeaderboard() {
        try {
          const response = await this.get(`${API_ENDPOINTS.golf.tournament}`);
          console.log('Raw tournament leaderboard response:', response);
          
          // Check the response structure
          const leaderboardData = response.data || response;
          console.log('Processed leaderboard data:', leaderboardData);
          
          return leaderboardData;
        } catch (error) {
          console.error('Error getting tournament leaderboard:', error);
          throw error;
        }
    }

    async getPlayers() {
        try {
            const response = await this.get(`${API_ENDPOINTS.golf.players}`);
            return response;
        } catch (error) {
            console.error('Error getting available players:', error);
            throw error;
        }
    }
     
    async getMySelections() {
      try {
          const response = await this.get(`${API_ENDPOINTS.golf.mySelections}`);
          
          // If there's no response or it's empty, return a default structure
          if (!response) {
              return { selections: [] };
          }
  
          // Format scores in selections if they exist
          if (response.selections) {
              response.selections = response.selections.map(player => ({
                  ...player,
                  formattedScore: this.formatScore(player.tournamentScore),
                  formattedPosition: this.formatPosition(player.tournamentPosition)
              }));
          } else {
              response.selections = [];
          }
          
          return response;
      } catch (error) {
          console.error('Error getting my selections:', error);
          // Return empty selections instead of throwing
          return { selections: [] };
      }
  }
     
    async submitSelections(selections) {
        try {
          console.log('Submit selections received:', selections);
          
          // Check if we received player IDs or player objects
          const playerIds = Array.isArray(selections) 
            ? selections.every(p => typeof p === 'string') 
              ? selections 
              : selections.map(player => player._id)
            : [];
      
          if (!playerIds.length === 3) {
            throw new Error('Must select exactly 3 players');
          }
      
          if (!playerIds.every(id => typeof id === 'string' && id.length > 0)) {
            throw new Error('Invalid player data');
          }
      
          console.log('Sending player IDs:', playerIds);
          
          const response = await this.post(`${API_ENDPOINTS.golf.selections}`, { 
            players: playerIds 
          });
          
          return response;
        } catch (error) {
          console.error('Error in submitSelections:', error);
          throw error;
        }
      }

      async getGameLeaderboard() {
        try {
          const response = await this.get(`${API_ENDPOINTS.golf.gameLeaderboard}`);
          console.log('Game leaderboard data:', response);
          return response;
        } catch (error) {
          console.error('Error getting game leaderboard:', error);
          throw error;
        }
      }

    // Get the last update time
    getLastUpdate() {
        return this.lastUpdate ? this.getUpdateTime(this.lastUpdate) : 'Never';
    }

    async getAvailableLeagues() {
        try {
          console.log('Fetching available leagues...');
          const response = await this.get('/golf/leagues');
          console.log('Available leagues response:', response);
          return response;
        } catch (error) {
          console.error('Error getting available leagues:', error);
          throw error;
        }
      }
    
      // Join a league
      async joinLeague(leagueId) {
        try {
          console.log('Joining league:', leagueId);
          const response = await this.post(`/golf/leagues/${leagueId}/join`);
          console.log('Join league response:', response);
          return response;
        } catch (error) {
          console.error('Error joining league:', error);
          throw error;
        }
      }

      async getLeagueLeaderboard(leagueId) {
        try {
          console.log('Fetching leaderboard for league:', leagueId);
          const response = await this.get(`${API_ENDPOINTS.golf.leagues}/${leagueId}/leaderboard`);
          console.log('League leaderboard response:', response);
          return response;
        } catch (error) {
          console.error('Error getting league leaderboard:', error);
          throw error;
        }
      }
}

export const golfService = new GolfService();
export { GolfService };